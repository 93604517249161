import { Machine, assign } from 'xstate'

const projectContainerMachine = Machine(
  {
    id: 'projects',
    initial: 'idle',
    context: {
      showAll: false,
      disabledFilter: null,
      canHide: true,
      filters: [],
      projects: [],
      transitioningProjects: []
    },
    states: {
      idle: {
        entry: [
          'syncProjects',
          assign({
            showAll: ctx =>
              ctx.projects.filter(p => !p.hidden).length <= 12 || ctx.showAll,
            canHide: ctx => ctx.projects.filter(p => !p.hidden).length > 12
          })
        ],
        on: {
          FILTER: [
            {
              target: 'transitioning.filteringProjects',
              cond: (ctx, e) => {
                const { name } = e
                const { filters } = ctx
                const filterIsOn = filters.includes(name)
                const isLastFilter = filters.length === 1 && filterIsOn

                if (!name || !filterIsOn || isLastFilter) {
                  return false
                }

                return true
              }
            },
            {
              target: 'transitioning.unfilteringProjects',
              cond: (ctx, e) => {
                const { name } = e
                const { filters } = ctx
                const filterIsOn = filters.includes(name)

                if (!name || filterIsOn) {
                  return false
                }

                return true
              }
            }
          ],
          SHOW: {
            target: 'transitioning.togglingShowAll',
            cond: ctx => {
              const { projects, showAll } = ctx

              if (showAll) {
                return projects.filter(p => !p.hidden).length > 12
              }

              return true
            }
          }
        }
      },
      transitioning: {
        states: {
          filteringProjects: {
            entry: ['updateFilters', 'setTransitioningProjects']
          },
          unfilteringProjects: {
            entry: ['updateFilters', 'syncProjects', 'setTransitioningProjects']
          },
          togglingShowAll: {
            entry: ['toggleShowAll']
          }
        },
        after: {
          200: 'idle' // length of the animation tw class `duration-200`
        }
      }
    }
  },
  {
    actions: {
      syncProjects: assign({
        projects: ctx =>
          ctx.projects.map(project => ({
            ...project,
            hidden: !ctx.filters.includes(project.type)
          })),
        transitioningProjects: () => [],
        disabledFilter: ctx =>
          ctx.filters.length === 1 ? ctx.filters[0] : null
      }),
      updateFilters: assign({
        filters: (ctx, e) => {
          if (ctx.filters.includes(e.name)) {
            return ctx.filters.filter(filter => filter !== e.name)
          } else {
            return ctx.filters.concat(e.name)
          }
        }
      }),
      setTransitioningProjects: assign({
        transitioningProjects: (ctx, e) => {
          const projects = ctx.projects.filter(
            project => project.type === e.name
          )
          return projects.map(project => project.id)
        }
      }),
      toggleShowAll: assign({
        showAll: ctx => !ctx.showAll
      })
    },
    activities: {},
    guards: {},
    services: {}
  }
)

export default projectContainerMachine
