import React from 'react'
// import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import cx from 'classnames'
import GalleryMeta from './gallery-meta'

function shuffle(a) {
  const newArray = a.slice()
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[newArray[i], newArray[j]] = [newArray[j], newArray[i]]
  }

  return newArray
}

const Photo = ({ photo, first, className, full, half }) => {
  return (
    <div
      className={cx(
        'group relative bg-cover bg-center overflow-hidden',
        className,
        {
          'w-1/2 pt-1/2': half,
          'w-1/2 pt-1/2 md:w-auto md:pt-0 md:absolute md:inset-0 transition-opacity duration-500 ease-linear': full,
          'w-1/2 pt-1/2 md:w-1/6 md:pt-1/6': !half && !full,
          'md:z-0 md:opacity-0': full && !first,
          'md:z-10 opacity-1': full && first
        }
      )}
      style={{
        backgroundImage: `url(${photo.url})`
      }}
    >
      {(photo.people.length || photo.caption) && (
        <GalleryMeta
          caption={photo.caption}
          people={photo.people}
          full={full}
        />
      )}
    </div>
  )
}

const CulturePhotos = ({ photos }) => {
  const [activePhoto, setActivePhoto] = React.useState(0)
  const [shuffledPhotos, setShuffledPhotos] = React.useState([])

  React.useEffect(() => {
    setShuffledPhotos(shuffle(photos))
    setShuffledPhotos(photos)
  }, [photos])

  React.useEffect(() => {
    const rotator = setTimeout(() => {
      const active = activePhoto < photos.length - 1 ? activePhoto + 1 : 0
      setActivePhoto(active)
    }, 5000)

    return () => {
      clearTimeout(rotator)
    }
  }, [activePhoto, photos])

  return (
    <article className="flex flex-wrap w-full md:relative md:w-1/2 md:pt-1/2">
      {shuffle.length &&
        shuffledPhotos.map((photo, index) => (
          <Photo
            full
            photo={photo}
            first={index === activePhoto}
            key={index.toString()}
          />
        ))}
    </article>
  )
}

const Gallery = ({ culturePhotos, generalPhotos, processPhotos }) => {
  return (
    <section>
      <div className="flex flex-wrap">
        <CulturePhotos photos={culturePhotos} />
        <aside className="w-full md:w-1/2 flex flex-wrap">
          {shuffle(processPhotos)
            .slice(0, 4)
            .map((photo, index) => (
              <Photo half photo={photo} key={index.toString()} />
            ))}
        </aside>
      </div>

      <ul className="list-none p-0">
        <li className="flex flex-wrap">
          {shuffle(generalPhotos)
            .slice(0, 6)
            .map((photo, index) => (
              <Photo photo={photo} key={index.toString()} />
            ))}
        </li>
      </ul>
    </section>
  )
}

// @TODO static query instead of fragment?
export const galleryFragment = graphql`
  fragment galleryFields on Craft_homepage_homepage_Entry {
    processPhotos {
      id
      url
      ...GalleryMetaFields
    }
    culturePhotos {
      id
      url
      ...GalleryMetaFields
    }
    generalPhotos {
      id
      url
      ...GalleryMetaFields
    }
  }
`

export default Gallery
