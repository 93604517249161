import React from 'react'
import { graphql } from 'gatsby'

const FeatureImage = ({ img }) => {
  return (
    <section
      className="relative bg-cover bg-center md:h-8/10"
      style={{
        backgroundImage: `url(${img.url})`
      }}
    >
      <img className="block w-full md:hidden" src={img.featureSmall} alt="" />

      {img.people.length && img.caption && (
        <div className="absolute bottom-0 left-0 mx-8point3 my-40 flex items-center">
          {img.people.length && (
            <a
              className="block flex-shrink-0 mr-12"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.viget.com/about/team/${img.people[0].vigetComSlug}`}
            >
              <img
                className="w-64 h-64 rounded-full"
                src={img.people[0].headshot[0].url}
                alt={`Photo of ${img.people[0].title}`}
              />
            </a>
          )}

          {img.caption && (
            <p className="antialiased text-white uppercase text-12 font-display">
              {img.caption}
            </p>
          )}
        </div>
      )}
    </section>
  )
}

export const FeatureImageFields = graphql`
  fragment FeatureImageFields on Craft_homepage_Asset {
    id
    url
    featureSmall: url(handle: "featureSmall", immediately: true)
    caption
    people {
      ... on Craft_people_people_Entry {
        id
        title
        vigetComSlug
        headshot {
          id
          url
        }
      }
    }
  }
`

export default FeatureImage
