import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { FaqContainer, Faq } from '../components/faq'
import ProjectContainer from '../components/projects-container'
import Gallery from '../components/gallery'
import FeatureImage from '../components/feature-image'
// about
import ToolsIcon from '../images/svgs/tools.inline.svg'
import ExperimentsIcon from '../images/svgs/experiments.inline.svg'
import StuntsIcon from '../images/svgs/stunts.inline.svg'
// contact
import ContactIcon from '../images/svgs/contact.inline.svg'
import HireIcon from '../images/svgs/hire.inline.svg'
import JoinIcon from '../images/svgs/join.inline.svg'
// process
import ProcessIcon from '../images/svgs/process.inline.svg'

const IconListItem = ({ children }) => (
  <li className="text-16 mx-40 mb-40 md:mb-0 uppercase font-display text-center text-brown-500">
    {children}
  </li>
)

const ContactLink = ({ to, title, content, children }) => (
  <a
    href={to}
    className="flex flex-col items-center px-24 md:px-40 lg:px-80 text-center no-underline pt-80 pb-40 md:pt-100 md:pb-70"
  >
    <div className="relative w-160 h-160 mb-24">{children}</div>
    <h3 className="uppercase text-36 mb-12 text-red-800">{title}</h3>
    <div
      className="text-innerp-red-900 md-d:text-innerp-20"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  </a>
)

const IndexPage = ({
  data: {
    craft: { entries }
  }
}) => {
  const page = entries[0]

  React.useEffect(() => {
    document.documentElement.style.setProperty('--body-color', '#918986')
  }, [])

  return (
    <Layout>
      <SEO title="Home" />

      <section
        id="hero"
        className="h-screen pt-160 min-h-520 flex items-center bg-cover bg-center"
        style={{
          backgroundImage: `url(${page.featureImages[0].url})`
        }}
      >
        <h1 className="max-w-1220 mx-auto px-12 sm:px-24 lg:px-12 text-36 sm:text-48 md:text-80 lg:text-96 text-white uppercase ">
          {page.heroText}
        </h1>
      </section>

      <section id="about" className="textured-split-bg py-120">
        <ul className="flex md-d:flex-wrap justify-center list-none p-0 mb-16 md:mb-60">
          <IconListItem>
            <ToolsIcon className="block mb-16" /> Tools
          </IconListItem>
          <IconListItem>
            <ExperimentsIcon className="block mb-16" /> Experiments
          </IconListItem>
          <IconListItem>
            <StuntsIcon className="block mb-16" /> Stunts
          </IconListItem>
        </ul>

        <div
          className="nested-about-text max-w-980 mx-auto px-12 text-innerp-20 md:text-innerp-36 mb-innerp-40 leading-innerp-tight font-display uppercase text-center"
          dangerouslySetInnerHTML={{ __html: page.aboutText }}
        />
      </section>

      <ProjectContainer />

      {page.featureImages[1] && <FeatureImage img={page.featureImages[1]} />}

      <section
        id="process"
        className="textured-split-bg font-display uppercase text-center py-120"
      >
        <header className="mb-50">
          <ProcessIcon className="mb-16" />
          <h2 className="text-16 text-brown-500">Process</h2>
        </header>
        <div
          className="nested-process-text max-w-740 mx-auto px-24 md:px-12 text-innerp-20 md:text-innerp-48 leading-innerp-snug md:leading-innerp-none"
          dangerouslySetInnerHTML={{ __html: page.processText }}
        />
      </section>

      <Gallery
        culturePhotos={page.culturePhotos}
        generalPhotos={page.generalPhotos}
        processPhotos={page.processPhotos}
      />

      <FaqContainer>
        {page.faq.map(({ __typename, ...faq }, index) => (
          <Faq key={index + __typename} {...faq} startOpen={index === 0} />
        ))}
      </FaqContainer>

      <section
        id="contact"
        className="flex md-d:flex-wrap textured-solid-bg justify-center"
      >
        <ContactLink
          to="https://www.viget.com/contact"
          title="Contact Us"
          content={page.contactUs}
        >
          <ContactIcon className="absolute bottom-0 left-1/2 transform -translate-x-1/2 block" />
        </ContactLink>
        <ContactLink
          to="https://www.viget.com/contact"
          title="Hire Viget"
          content={page.hireViget}
        >
          <HireIcon className="absolute bottom-0 left-1/2 transform -translate-x-1/2 block" />
        </ContactLink>
        <ContactLink
          to="https://www.viget.com/careers"
          title="Join Viget"
          content={page.joinViget}
        >
          <JoinIcon className="absolute bottom-0 left-1/2 transform -translate-x-1/2 block" />
        </ContactLink>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query HomepageQuery {
    craft {
      entries(section: "homepage") {
        title
        ...galleryFields
        ... on Craft_homepage_homepage_Entry {
          aboutText
          processText
          heroText
          contactUs
          hireViget
          joinViget
          featureImages {
            ...FeatureImageFields
          }
          faq {
            ...faqFields
          }
        }
      }
    }
  }
`

export default IndexPage
