import React from 'react'
import { graphql } from 'gatsby'
import cx from 'classnames'
import FaqIcon from '../images/svgs/faq.inline.svg'
import MinusIcon from '../images/svgs/icon-minus.inline.svg'
import PlusIcon from '../images/svgs/icon-plus.inline.svg'
import { Machine } from 'xstate'
import { useMachine } from '@xstate/react'

export const faqMachine = Machine(
  {
    id: 'faq',
    initial: 'start',
    context: {
      startOpen: false
    },
    states: {
      start: {
        on: {
          '': [{ target: 'open', cond: 'startOpen' }, { target: 'closed' }]
        }
      },
      closed: {
        on: {
          TOGGLE: 'open'
        }
      },
      open: {
        on: {
          TOGGLE: 'closed'
        }
      }
    }
  },
  {
    guards: {
      startOpen: (context, _event) => {
        return context.startOpen
      }
    }
  }
)

export const FaqContainer = ({ children }) => {
  return (
    <section id="faq" className="py-60">
      <header className="mb-50 text-center">
        <FaqIcon className="mb-16" />
        <h2 className="text-16 text-brown-500">FAQ</h2>
      </header>

      <ul className="list-none max-w-980 mx-auto p-12">{children}</ul>
    </section>
  )
}

export const Faq = ({ question, answer, startOpen }) => {
  const [current, send] = useMachine(faqMachine, {
    context: {
      startOpen
    }
  })

  return (
    <li className="mb-40">
      <button
        onClick={() => {
          send('TOGGLE')
        }}
        className="font-display text-left flex items-center text-misc-experiments uppercase leading-relaxed"
      >
        <span
          className={cx(
            'flex flex-shrink-0 justify-center items-center w-24 h-24 rounded-full mr-16 transition-colors duration-200 ease-linear',
            {
              'bg-misc-tools': current.matches('open'),
              'bg-misc-experiments': current.matches('closed')
            }
          )}
        >
          {current.matches('open') ? <MinusIcon /> : <PlusIcon />}
        </span>
        {question}
      </button>
      <div
        className={cx('pl-40', {
          hidden: current.matches('closed')
        })}
        dangerouslySetInnerHTML={{ __html: answer }}
      />
    </li>
  )
}

export const faqFragment = graphql`
  fragment faqFields on Craft_faq_entry_BlockType {
    question
    answer
  }
`
