import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import cx from 'classnames'
import { useMachine } from '@xstate/react'
import ProjectCard from './project-card'
import projectContainerMachine from './machines/projects-container'
import IconX from '../images/svgs/icon-x.inline.svg'
import IconCheck from '../images/svgs/icon-check.inline.svg'

const filters = [
  {
    name: 'tools',
    label: 'Tools'
  },
  {
    name: 'experiments',
    label: 'Experiments'
  },
  {
    name: 'stunts',
    label: 'Stunts'
  }
]

const Switch = ({ name, htmlFor, children, className, ...props }) => {
  const id = htmlFor || name

  return (
    <div className={cx('switch', className)}>
      <input type="checkbox" name={name} id={id} {...props} />
      <label htmlFor={id}>
        {children}
        <span className="switch-inner">
          <IconCheck className="absolute top-0 left-0 my-4 mx-6 text-green-900 z-0" />
          <IconX className="absolute top-0 right-0 my-4 mx-6 text-red-950 z-0" />
        </span>
      </label>
    </div>
  )
}

const Radio = ({ name, htmlFor, children, className, ...props }) => {
  return (
    <div className={cx('radio', className)}>
      <input type="radio" name={name} id={htmlFor} {...props} />
      <label htmlFor={htmlFor}>{children}</label>
    </div>
  )
}

const ProjectsContainer = () => {
  const {
    craft: { entries }
  } = useStaticQuery(graphql`
    query projects {
      craft {
        entries(section: "projects", orderBy: "RAND()") {
          id
          title
          slug
          ... on Craft_projects_projects_Entry {
            backgroundColor
            linkColor
            tagline
            icon {
              url
            }
            projectType {
              title
              slug
              ... on Craft_projects_Category {
                icon {
                  url
                }
                backgroundColor
              }
            }
          }
        }
      }
    }
  `)

  const [current, send] = useMachine(projectContainerMachine, {
    context: {
      filters: filters.map(item => item.name),
      projects: entries.map(entry => ({
        ...entry,
        type: entry.projectType[0].slug,
        hidden: false
      }))
    }
  })

  const {
    context: {
      transitioningProjects,
      showAll,
      projects,
      disabledFilter,
      canHide
    }
  } = current

  return (
    <section id="projects" className="px-12 sm:px-24 py-60">
      <h2 className="text-red-800 text-36 uppercase text-center sm-d:mb-40">
        Projects
      </h2>

      <div className="hidden sm:flex flex-wrap items-center justify-center py-40">
        <div className="flex items-center lg-d:mb-16 lg-d:justify-center lg-d:w-full pr-24 mr-24 lg:border-r lg:border-yellow-200">
          <h3 className="text-14 text-red-800 uppercase">Filter:</h3>
          {filters.map(item => (
            <Switch
              key={item.name}
              onChange={() => {
                send({
                  type: 'FILTER',
                  name: item.name
                })
              }}
              checked={current.context.filters.includes(item.name)} // !isON
              className="ml-16"
              name={item.name}
              disabled={disabledFilter === item.name}
            >
              {item.label}
            </Switch>
          ))}
        </div>
        <div className="flex items-center lg-d:justify-center lg-d:w-full">
          <h3 className="text-14 text-red-800 uppercase">Show</h3>
          <Radio
            checked={!showAll}
            onChange={() => send('SHOW')}
            className="ml-16"
            name="show"
            htmlFor="top-12"
            disabled={!canHide}
          >
            Top 12
          </Radio>
          <Radio
            checked={showAll}
            onChange={() => send('SHOW')}
            className="ml-16"
            name="show"
            htmlFor="all"
          >
            All
          </Radio>
        </div>
      </div>

      <div className="waves-bg p-16 rounded-lg mb-40">
        <ul className="flex flex-wrap list-none p-0">
          {projects
            .filter(p => !p.hidden)
            .map(
              (project, index) =>
                shouldProjectShow(index, showAll) && (
                  <ProjectCard
                    className={cx({
                      'opacity-25 transition-opacity duration-200 ease-linear':
                        !transitioningProjects.includes(project.id) &&
                        current.matches('transitioning'),
                      'opacity-0 transition-opacity duration-200 ease-linear': transitioningProjects.includes(
                        project.id
                      )
                    })}
                    project={project}
                    key={project.id}
                  />
                )
            )}
        </ul>
      </div>

      {!showAll && (
        <button
          onClick={() => {
            send('SHOW')
          }}
          className="flex items-center h-120 w-120 mx-auto border-5 border-red-700 rounded-full no-underline"
        >
          <span className="block uppercase text-center font-display text-12 text-red-700">
            View More Projects
          </span>
        </button>
      )}
    </section>
  )
}

const shouldProjectShow = (index, showAll) => {
  const notTop12 = index > 11

  if (!showAll && notTop12) {
    return false // hide if not showing all and is past the 12th project
  }

  return true
}

export default ProjectsContainer
