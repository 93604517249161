import React from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import StampExperiments from '../images/svgs/stamp-experiments.inline.svg'
import StampStunts from '../images/svgs/stamp-stunts.inline.svg'
import StampTools from '../images/svgs/stamp-tools.inline.svg'

const ProjectCard = ({ project, className }) => {
  const category = project.projectType[0]
  const icon =
    project.icon.length > 0 ? project.icon[0].url : category.icon[0].url
  const backgroundColor = project.backgroundColor || category.backgroundColor
  const linkColor = project.linkColor || category.linkColor

  return (
    <li
      className={cx('flex w-full sm:w-1/2 md:w-1/3 xl:w-1/4 p-8', className)}
      data-category={category.slug}
    >
      <Link
        className="project-card relative block w-full pb-80 overflow-hidden no-underline rounded text-white text-center"
        style={{ backgroundColor: backgroundColor }}
        to={`/${project.slug}`}
      >
        <img className="block w-full max-w-300 mx-auto" src={icon} alt="" />

        <div className="px-16 -mt-40 mb-12">
          <h3 className="uppercase text-20 mb-8">{project.title}</h3>
          <p className="lg-d:text-14">{project.tagline}</p>
        </div>

        <div
          className="project-card-icon absolute left-1/2 -ml-60 w-120 h-120"
          style={{ bottom: -55 }}
        >
          {category.slug === 'experiments' ? (
            <StampExperiments
              className="w-full h-full"
              style={{ color: linkColor }}
            />
          ) : category.slug === 'stunts' ? (
            <StampStunts
              className="w-full h-full"
              style={{ color: linkColor }}
            />
          ) : (
            <StampTools
              className="w-full h-full"
              style={{ color: linkColor }}
            />
          )}
        </div>
      </Link>
    </li>
  )
}

export default ProjectCard
