import React from 'react'
import { graphql } from 'gatsby'
import cx from 'classnames'

const GalleryMeta = ({ caption, people, full }) => {
  const [colorClass, setColorClass] = React.useState(-1)

  React.useEffect(() => {
    if (!full) {
      setColorClass(Math.floor(Math.random() * Math.floor(3)))
    }
  }, [colorClass, full])

  return (
    <div
      className={cx(
        'absolute flex items-center bottom-0 left-0 right-0 z-20 p-16 transition-transform ease-linear duration-200 transform translate-y-full group-hover:translate-y-0',
        {
          'bg-gray-800': colorClass === -1,
          'bg-misc-tools': colorClass === 0,
          'bg-misc-experiments': colorClass === 1,
          'bg-misc-stunts': colorClass === 2
        }
      )}
    >
      {!!people.length && (
        <ul className="list-none p-0 flex flex-shrink-0 mr-16">
          {people.map((person, index) => (
            <li
              className={cx({
                'ml-12': index !== 0
              })}
              key={person.id}
            >
              <a
                href={`https://www.viget.com/about/team/${person.vigetComSlug}`}
              >
                <img
                  className="block w-64 h-64 rounded-full"
                  src={`${person.headshot[0].url}`}
                  alt={`Photo of ${person.title}`}
                />
              </a>
            </li>
          ))}
        </ul>
      )}
      {caption && (
        <p className="antialiased font-display text-12 text-white uppercase">
          {caption}
        </p>
      )}
    </div>
  )
}

export const GalleryMetaFields = graphql`
  fragment GalleryMetaFields on Craft_homepage_Asset {
    caption
    people {
      ... on Craft_people_people_Entry {
        id
        title
        vigetComSlug
        headshot {
          id
          url
        }
      }
    }
  }
`

export default GalleryMeta
